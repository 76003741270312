import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_7/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      leftText: mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-2"}}) {
        body
      }
      bulletTexts:  mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "army-making-bullets"}}) {
        exports {
          bulletsInfo {
            caliber
            type
          }
        }
      }
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
