import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_7/slide1';
import { getLangFromPath } from 'src/utils';
import { mediaServer } from '../../../../../../globals';

const Slide = () => {
  const language = getLangFromPath();
  const query = useStaticQuery(graphql`
  query {
    usa:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-1"}}) {
      body
    }
    brazylia:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-2"}}) {
      body
    }
    haller:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-3"}}) {
      body
    }
    murman:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-4"}}) {
      body
    }
    weterani:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-5"}}) {
      body
    }
    polska:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-6"}}) {
      body
    }
    wojsko:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-7"}}) {
      body
    }
    zeligowski:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-8"}}) {
      body
    }
    syberia:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-9"}}) {
      body
    }
    dotArray:  mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "army-making-hovers"}}) {
      exports {
        hoverInfo {
          insideText
          isPolish
          isLeft
          left
          top
          backgroundColor
          colorText
          index
        }
      }
    }
    initialSlide: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "UA" }
          title: { eq: "slide-4-7" }
        }
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    audio1Photo: file(relativePath: { eq: "chapter_4/sub_7/desktop/471witkowski.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audio2Photo: file(relativePath: { eq: "chapter_4/sub_7/desktop/471haller.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audio3Photo: file(relativePath: { eq: "chapter_4/sub_7/desktop/471murmanski.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audio4Photo: file(relativePath: { eq: "chapter_4/sub_7/desktop/471werobej.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);

  const modalInfo = [
    {
      body: query.usa.body,
      sound: [`${mediaServer}/webm/${language.toLowerCase()}/471witkowski.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/471witkowski.mp4`],
      photo: query.audio1Photo.childImageSharp.fluid,
    },
    {
      body: query.brazylia.body,
      photo: query.audio1Photo.childImageSharp.fluid,
    },
    {
      body: query.haller.body,
      sound: [`${mediaServer}/webm/${language.toLowerCase()}/471haller.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/471haller.mp4`],
      photo: query.audio2Photo.childImageSharp.fluid,
    },
    {
      body: query.murman.body,
      sound: [`${mediaServer}/webm/${language.toLowerCase()}/471murmanski.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/471murmanski.mp4`],
      photo: query.audio3Photo.childImageSharp.fluid,
    },
    {
      body: query.weterani.body,
      photo: query.audio1Photo.childImageSharp.fluid,
    },
    {
      body: query.polska.body,
      photo: query.audio1Photo.childImageSharp.fluid,
    },
    {
      body: query.wojsko.body,
      photo: query.audio1Photo.childImageSharp.fluid,
    },
    {
      body: query.syberia.body,
      sound: [`${mediaServer}/webm/${language.toLowerCase()}/471werobej.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/471werobej.mp4`],
      photo: query.audio4Photo.childImageSharp.fluid,
    },
    {
      body: query.zeligowski.body,
      photo: query.audio1Photo.childImageSharp.fluid,
    },
  ];
  return (
    <Template
      initialScreen={query.initialSlide.edges[0].node.body}
      dotArray={query.dotArray.exports.hoverInfo}
      modalInfo={modalInfo}
    />
  );
};


export default Slide;
